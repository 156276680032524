import { authHeader } from './auth-header';
import axiosInstance from "./client"

export const residentService = {
    getAll,
    createOrUpdate,
    createBulk,
    getById,
    deleteById,
    getByCardNumber,
};

function getById(id) {
    const requestOptions = {
        url : '/api/v1/resident/' + id,
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
    //return fetch(`/api/v1/user/machine_type/` + machineTypeId, requestOptions).then(handleResponse);
}

function getAll(filter, page, size) {
    
    const requestOptions = {
        url : '/api/v1/resident?lorem' + (filter == undefined || filter.length < 3 ? "" : "&name="+filter) 
        + (page == undefined ? "" : "&page=" + --page )
        + (size == undefined ? "" : "&limit=" + size),
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance( requestOptions)
}

function getByCardNumber(filter) {
    
    const requestOptions = {
        url : '/api/v1/resident?card_number='+filter,
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance( requestOptions)
}


function createOrUpdate(jsonData) {

    let formData = new FormData();

    jsonData["ID"] = jsonData["id"]
    for ( var key in jsonData ) {
        if (jsonData[key] != null)
            formData.append(key, jsonData[key]);
    }
    let rMethod = jsonData["id"] != null  ? "PUT" : "POST"
    const requestOptions = {
        url : '/api/v1/resident',
        method: rMethod,
        data: formData,
        headers: authHeader()
    };

    return axiosInstance(requestOptions);
}

async function createBulk(jsonData) {


    let rMethod = "POST"
    const requestOptions = {
        url : '/api/v1/residents',
        method: rMethod,
        data: jsonData,
        headers: authHeader()
    };

    return await axiosInstance(requestOptions);
}


function deleteById(id) {
    const requestOptions = {
        url: `/api/v1/resident/` + id,
        method: 'DELETE',
        headers: authHeader()
    };
    return axiosInstance(requestOptions);
}

