<script>
import { residentService } from "../../../helpers/backend/resident.service";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
export default {
  components: {
    VuePhoneNumberInput,
    },
  data() {
    return {
      form: {
        id: null,
        nik: 0,
        card_number: 0,
        name: "",
        address: "",
        phone_number : ""
      },
      busy: false,
      show: true,
      showDismissibleAlert: false,
      error: "",
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    isUpdateState() {
      return this.form.id != null;
    },
    nikState() {
        return this.form.nik != null && ((this.form.nik).toString().length == 16 ? true : false)
      }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      //this.form.media = this.$refs.file.files[0]
      this.busy = true;
      residentService.createOrUpdate(this.form).then(
        (data) => {
          if (data != null) {
            this.reset();
            this.$emit("refresh");
            this.showDismissibleAlert = true;
            this.error = "";
          }
          this.busy = false;
        },
        (error) => {
          this.showDismissibleAlert = true;
          this.error = "Failed to Insert/Update Customer. Error : " + error;
          this.busy = false;
        }
      );
    },
    reset() {
      // Reset our form values
      this.form.id = null;
      this.form.nik = 0
      this.form.card_number = 0
      this.form.name = "";
      this.form.address = "";
      this.form.phone_number = "";
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.showDismissibleAlert = false;
      this.error = "";
      this.$nextTick(() => {
        this.show = true;
      });
    },
    onReset(event) {
      event.preventDefault();
      this.reset();
    },
    initUpdate(id) {
      this.reset();
      this.$refs.nik.$el.focus();
      this.busy = true;
      residentService.getById(id).then(
        (data) => {
          if (data.resident != null) {
            this.form.id = data.resident.ID;
            this.form.nik = data.resident.nik;
            this.form.card_number = data.resident.card_number;
            this.form.name = data.resident.name;
            this.form.address = data.resident.address;
            this.form.phone_number = data.resident.phone_number;
          } else {
            this.showDismissibleAlert = true;
            this.error =
              "Failed to get resident to update. Error : resident not found";
          }
          this.busy = false;
        },
        (err) => {
          this.showDismissibleAlert = true;
          this.error = "Failed to get resident to update. Error : " + err;
          this.busy = false;
        }
      );
    },
    fetchData() {
      
    },
  },
};
</script>


<template>
  <div>
    <b-alert
      :variant="error == '' ? 'success' : 'danger'"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
    >
      <div v-if="error != ''">
        {{ error }}
      </div>
      <div v-else>Success perform Insert/Update action</div>
    </b-alert>
    <div class="card">
      <div class="card-body">
        <div>
          <b-form @submit="onSubmit" @reset="onReset" v-if="show" ref="form">
            <b-overlay :show="busy" rounded="lg" opacity="0.6">
              <b-form-group
                id="input-group-1"
                label="Resident NIK:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.nik"
                  type="number"
                  placeholder="Enter Resident NIK"
                  required
                  :state="nikState"
                  ref="nik"
                ></b-form-input>
                <b-form-invalid-feedback id="nik-feedback">
                  NIK must be 16 digits
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label="Card Number:"
                label-for="input2"
              >
                <b-form-input
                  id="input-2"
                  v-model="form.card_number"
                  type="number"
                  placeholder="Enter Card Number"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-3"
                label="Name:"
                label-for="input3"
              >
                <b-form-input
                  id="input-3"
                  v-model="form.name"
                  type="text"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-4"
                label="Address:"
                label-for="input4"
              >
                <b-form-input
                  id="input-4"
                  v-model="form.address"
                  type="text"
                  required
                ></b-form-input>
              </b-form-group>
              
      <b-form-group
        id="input-group-5"
        label="Phone number:"
        label-for="input-5"
      >
      <VuePhoneNumberInput 
          id="input-5" 
          required
          :only-countries="['ID']"
          :default-country-code="'ID'"
          v-model="form.phone_number" />
      </b-form-group>
              <b-button type="submit" variant="primary">{{
                isUpdateState ? "Update" : "Submit"
              }}</b-button>
              <b-button type="reset" variant="danger">Reset</b-button>
            </b-overlay>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>



