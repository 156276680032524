<script>
import { residentService } from "../../../helpers/backend/resident.service";
import VueBarcode from "vue-barcode";
import { BIcon } from "bootstrap-vue";
/**
 * Transactions component
 */
export default {
  components: {
    barcode: VueBarcode,
    BIcon,
  },
  data() {
    return {
      listData: [],
      loading: true,
      totalRows: 1,
      rows: 0,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "ID", sortable: true, label: "ID" },
        { key: "nik", sortable: true, label: "NIK" },
        { key: "card_number", sortable: true, label: "Card Number" },
        { key: "name", sortable: true, label: "Name" },
        { key: "address", sortable: true, label: "Address" },
        { key: "phone_number", sortable: true, label: "Phone Number" },
        { key: "action" },
      ],
      showDismissibleAlert: false,
      resident_card_number: null,
      totalUpload: 0,
      currentUpload: 0,
      uploading: false,
      uploadData: [],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    // rows() {
    //   return this.listData.length;
    // },
  },
  created() {
    //this.fetchData();
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = 9999;
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    fetchData() {
      this.error = null;
      this.loading = true;
      this.showDismissibleAlert = false;
      //const fetchedId = this.$route.params.id
      residentService.getAll().then(
        (data) => {
          this.listData = data.residents;
          this.loading = false;
        },
        (err) => {
          this.error =
            "Failed to fetch product data. Error : " +
            err +
            "  Dissmiss to refresh";
          this.loading = false;
          this.showDismissibleAlert = true;
        }
      );
    },
    listProvider(context, callback){
      this.loading = true;
      residentService.getAll(this.filter == "" ? undefined : this.filter, context.currentPage, context.perPage).then(
        (data) => {
          this.loading = false;
          this.rows = data.row_count
          callback(data.residents)
        },
        (err) => {
          this.error =
            "Failed to fetch product data. Error : " +
            err +
            "  Dissmiss to refresh";
          this.loading = false;
          this.showDismissibleAlert = true;
        }
      );
    },
    onDelete(id) {
      if (
        confirm(
          "Do you really want to delete this instance, this action can't be undone?"
        )
      ) {
        residentService.deleteById(id).then(
          (data) => {
            // this.fetchData();
            console.log(data);
          },
          (err) => {
            this.error = "Failed to delete resident. Error : " + err;
            this.showDismissibleAlert = true;
          }
        );
      }
    },
    onUpdate(id) {
      this.$emit("onEdit", id);
      // this.$papa.parse(file)
    },
    onShowResident(id) {
      this.resident_card_number = id;
      this.$bvModal.show("show-resident-modal");
    },
    async saveImage() {
      const el = this.$refs.residentCard;
      const options = {
        type: "dataURL",
        width: 640,
        height: 420,
      };
      this.$html2canvas(el, options).then((canvas) => {
        const anchor = document.createElement("a");
        anchor.href = canvas;
        anchor.target = "_blank";
        anchor.download = "resident_card_" + this.resident_card_number + ".png";
        anchor.click();
      });
    },
    uploadResident(object) {
      for (let index = 0; index < object.target.files.length; index++) {
        const f = object.target.files[index];
        this.$papa.parse(f, {
          header: true,
          complete: (results) => {
            const doUpload = (result) => {
              var res = result
                .filter((val) => {
                  return val.card_number != "";
                })
                .map((val) => {
                  return {
                    nik: parseInt(val.nik),
                    card_number: parseInt(val.card_number),
                    name: val.name,
                    address: val.address,
                  };
                });
              this.error = null;
              this.loading = true;
              this.showDismissibleAlert = false;
              this.currentUpload = 0;
              this.totalUpload = res.length;
              this.uploading = true;
              while (res.length && this.uploading) {
                var res_splice = res.splice(0, 250);
                residentService.createBulk(res_splice);
                this.currentUpload += res_splice.length;
              }

              this.uploading = false;
            };
            doUpload(results.data);
          },
        });
      }
    },
  },
};
</script>

<template>
  <div>
    <b-overlay :show="uploading">
      <b-modal size="xl" title="Resident card" id="show-resident-modal">
        <div ref="residentCard" style="width:640;height;391">
          <img src="@/assets/images/front-blank.jpg" width="640" height="391" />
          <barcode
            :value="resident_card_number"
            format="CODE128"
            height="70"
            style="position: absolute; left: 5%; top: 70%"
          />
        </div>
        <b-button @click="saveImage" class="row float-right">Save</b-button>
      </b-modal>
      <b-alert
        variant="danger"
        dismissible
        fade
        :show="showDismissibleAlert"
        
      >
        {{ error }}
      </b-alert>

      <div class="card">
        <div class="card-body">
          <!-- <b-dropdown right toggle-class="arrow-none card-drop" class="float-right" variant="white">
        <template v-slot:button-content>
          <i class="mdi mdi-dots-vertical"></i>
        </template>
        <b-dropdown-item>Sales Report</b-dropdown-item>
        <b-dropdown-item>Export Report</b-dropdown-item>
        <b-dropdown-item>Profit</b-dropdown-item>
        <b-dropdown-item>Action</b-dropdown-item>
      </b-dropdown> -->

          <h4 class="card-title mb-4">Resident</h4>
          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>

                  <input
                    type="file"
                    ref="file"
                    style="display: none"
                    @change="uploadResident"
                  />
                  <b-button @click="$refs.file.click()">Import CSV</b-button>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <div class="table-responsive">
            <b-table
              :items="listProvider"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              filter-debounce=200
            >
              <template v-slot:cell(vehicle_type_id)="row">
                {{
                  row.item.vehicle_type.vehicle_brand != ""
                    ? row.item.vehicle_type.vehicle_brand +
                      " " +
                      row.item.vehicle_type.vehicle_series
                    : "Universal"
                }}
              </template>

              <template v-slot:cell(action)="row">
                <a
                  @click="onUpdate(row.item.ID)"
                  class="mr-3 text-primary"
                  v-b-tooltip.hover
                  data-toggle="tooltip"
                  title="Edit"
                >
                  <i class="mdi mdi-pencil font-size-18"></i>
                </a>
                <a
                  @click="onShowResident(row.item.card_number)"
                  class="mr-3 text-primary"
                  v-b-tooltip.hover
                  data-toggle="tooltip"
                  title="Show"
                >
                  <i class="mdi mdi-eye font-size-18"></i>
                </a>
                <a
                  @click="onDelete(row.item.ID)"
                  class="text-danger"
                  v-b-tooltip.hover
                  title="Delete"
                >
                  <i class="mdi mdi-trash-can font-size-18"></i>
                </a>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #overlay>
        <div class="text-center">
          <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label">Uploading ... {{ currentUpload }} / {{totalUpload}}</p>
          <b-button
            ref="cancel"
            variant="outline-danger"
            size="sm"
            aria-describedby="cancel-label"
            @click="uploading = false"
          >
            Cancel
          </b-button>
        </div>
      </template>
    </b-overlay>
  </div>
</template>